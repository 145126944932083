import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/peterkrajcovic/Documents/Web/GatsbyProject/globaltel_V5_actual/src/components/newsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>18 rokov slovensko.sk</h1>
    <p>
    Pred 18-timi rokmi, 23.4.2003 bol na dnes už neexistujúcej výstave COFAX verejnosti prvý krát
    predstavený Ústredný portál verejnej správy. Bol sprístupnený na adrese obcan.sk, pod ktorým ho
    ľudia poznali a bol vnímaný ako jeden z míľnikov budovania eGovernmentu na Slovensku. Jeho
    vtedajšia obľúbenosť medzi ľuďmi bola na úrovni komerčných webov.
    </p>
    <p>
    V rokoch 2006-2008 bola rozšírená funkcionalita portálu, pridané boli schránky, elektronické
    podpisy, formuláre a modul komunikácie. V tom čase najúspešnejším bol formulár pre dnes už
    neexistujúcu agendu podania pre ročné zúčtovanie zdravotného poistenia, ktorý za mesiac použilo
    viac
    ako 800.000 používateľov. Zabezpečoval integráciu Živnostenského registra s Obchodným registrom,
    Registrom trestov a informačným systém Daňového riaditeľstva, aj vďaka čomu sa podarilo skrátiť
    získanie živnostenského listu z pôvodných 30 na 5 dní.
    </p>
    <p>
    V roku 2009 sa začala pripravovať jeho druhá verzia, znamenajúca významný funkčný a výkonnostný
    nárast. Do roku 2014 sa rozšíril o autentifikáciu, zaručené doručovanie, existujúce moduly sa
    migrovali do nových technológií s rozšírenou funkcionalitou. Zároveň sa sprístupnil na novej
    adrese,
    slovensko.sk.
    </p>
    <p>
    Ďalšie roky boli venované zmenám, vyplývajúcim z legislatívy, optimalizácii a ladeniu výkonu pre
    stále rastúcu záťaž a integráciám s informačnými systémami verejnej správy. Stal sa súčasťou
    štátnej
    administratívy, bez neho by mnohé procesy štátu nefungovali. 30 miliónov správ v roku 2020,
    zabezpečujúcich rôzne konania verejnej správy, sú toho dôkazom.
    </p>
    <p>Všetko najlepšie, slovensko.sk!</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      